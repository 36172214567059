.adminLoginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(
        to right,
        #020307,
        #000b2c,
        #002746,
        #000f35f3,
        #050c25,
        #000a27,
        #001b31,
        #010308
    );
    background-size: 400% 100%;
  }
  
  .loginForm {
    background: linear-gradient(90deg, #040404, #000);
    border-radius: 8px;
    box-shadow: 0 2px 4px #0000001a;
    max-width: fit-content;
    padding: 2rem;
    width: 100%;
}
  
  .loginTitle {
    font-family: 'Montserrat', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;
    letter-spacing: 3px;
  }
  
  .passwordInput {
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 1rem;
    padding: 10px;
    width: 100%;
    max-width: 100%;
}
  
  .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #1a73e8;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #1557b0;
  }

  .errorMessage {
    color: #ffffff;
    background-color: #a72d2d;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1rem;
    font-size: 14px;
    text-align: center;
}
