@tailwind base;
@tailwind components;
@tailwind utilities;

.mb-3 {
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}


button,
input,
optgroup,
select,
textarea {
 font-feature-settings: inherit;
    border-radius: 10px;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    font-variation-settings: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    max-width: 400px;
    padding: 15px;
    height: 52px;
}

#louvor-ministerio-container {
  padding: 20px;
  background: linear-gradient(
    to right,
    #020307,
    #000b2c,
    #002746,
    #000f35f3,
    #050c25,
    #000a27,
    #001b31,
    #010308
  );
  background-size: 400% 100%;
  min-height: 100vh;
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

#louvor-ministerio-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

#louvor-ministerio-header::before {
  content: "";
  display: inline-block;
  width: 61px; /* Tamanho fixo para garantir que a imagem não fique comprimida */
  height: 61px; /* Certifique-se de que a altura seja igual à largura para manter o círculo */
  background-image: url(./logo-favicon-chat.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid white;
}

/* Ajuste para dispositivos móveis */
@media (max-width: 768px) {
  #louvor-ministerio-header::before {
    width: 45px; /* Tamanho menor para telas pequenas */
    height: 45px; /* Mantém o círculo na proporção correta */
    background-size: cover;
  }
}
#louvor-ministerio-title {
  font-size: 18px;
  color: white;
  margin-left: 20px; /* Espaço entre a imagem e o texto */
  text-align: left;
}

#search-container {
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

#search-input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #fff0;
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  height: 50px;
  background: #ffffff1c;
  border: 1px solid #ffffff4d;
  color: white;
  margin-bottom: 10px;
}

#toggle-weekly-verse-btn {
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 10%) 0%,
    rgb(255 255 255 / 5%) 70.31%
  );
  -webkit-clip-path: polygon(85% 0, 100% 20%, 100% 100%, 15% 100%, 0 80%, 0 0);
  clip-path: polygon(85% 0, 100% 20%, 100% 100%, 15% 100%, 0 80%, 0 0);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
  text-align: center;
  border: 2px solid #ffffff1a;
  transition: transform 0.5s ease, background-color 0.3s ease; /* Suave transição */
}

/* Efeito de hover */
#toggle-weekly-verse-btn:hover {
  transform: scale(1.05); /* Aumenta a escala em 5% no hover */
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 20%) 0%,
    rgb(255 255 255 / 10%) 70.31%
  ); /* Fundo ligeiramente mais claro */
}

/* Efeito de hover */
#toggle-weekly-verse-btn:hover {
  transform: scale(1.02); /* Aumenta o tamanho do botão */
}

#versiculos-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

/* Estilos para o modal de playlist */
.playlist-modal .modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.playlist-modal .modal-header {
  background-color: transparent;
  border-bottom: 1px solid #e9ecef;
}

.playlist-modal .modal-title {
  font-size: 1.5rem;
  color: #ffffff;
}

.playlist-modal .modal-body {
  padding: 20px;
}

.playlist-modal .form-label {
  font-weight: bold;
  color: #ffffff;
}

.playlist-modal .form-select,
.playlist-modal .form-control {
  margin-bottom: 15px;
}

.playlist-modal .modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.playlist-modal .btn-secondary {
  background-color: #000000;
  color: white;
  border: 2px solid white;
}

.playlist-modal .btn-primary {
  background-color: #ffffff2e;
  border: 1px solid white;
  color: white;
  padding: 0px 90px;
}

.playlist-modal .btn-secondary:hover,
.playlist-modal .btn-primary:hover {
  opacity: 0.9;
}

.versiculo-card {
  background: linear-gradient(
    360deg,
    rgba(222, 218, 242, 0.1) 0%,
    rgba(222, 218, 242, 0.05) 70.31%
  ); /*cor dos gradientes de fundo, altere se necessário*/
  -webkit-clip-path: polygon(
    85% 0,
    100% 20%,
    100% 100%,
    15% 100%,
    0 80%,
    0 0
  ); /*configurações de clippath*/
  clip-path: polygon(
    85% 0,
    100% 20%,
    100% 100%,
    15% 100%,
    0 80%,
    0 0
  ); /*configurações de clippath*/
  position: relative;
  transition: transform 0.5s ease, background-color 0.5s ease;
  width: 100%;
  max-width: 400px;
}

/* Efeito de hover */
.versiculo-card:hover {
  transform: scale(1.02); /* Aumenta o tamanho do botão */
}

.versiculo-card::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    #120077 100%
  ); /*cor da borda*/
  border-left: 1px solid; /*largura e tipo de borda lateral esquerda*/
  border-image-slice: 1;
}

/* Efeito de hover */
#toggle-weekly-verse-btn:hover {
  transform: scale(1.05); /* Aumenta a escala em 5% no hover */
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 20%) 0%,
    rgb(255 255 255 / 10%) 70.31%
  ); /* Fundo ligeiramente mais claro */
}

.versiculo-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.versiculo-info {
  padding: 15px;
}

.versiculo-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
}

.versiculo-singer {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
}

.versiculo-tones {
  margin-bottom: 30px;
  color: white;
  font-weight: 600;
}


.audio-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.skip-btn,
.play-pause-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
  color: white;
  display: flex;
  justify-content: center;
}

.play-pause-btn {
  color: #ffffff;
  border: 2px solid white;
  border-radius: 50%; /* Altera para 50% para garantir um círculo perfeito */
  padding: 0; /* Remover padding para manter a forma circular */
  width: 50px; /* Largura do botão */
  height: 50px; /* Altura igual à largura para um círculo perfeito */
  display: flex; /* Usar Flexbox para centralizar conteúdo */
  justify-content: center; /* Centralizar horizontalmente */
  align-items: center; /* Centralizar verticalmente */
  cursor: pointer; /* Mostrar que é clicável */
  transition: background-color 0.3s ease; /* Transição suave para efeitos de hover */
}

/* Efeito de hover opcional */
.play-pause-btn:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Alterar fundo ao passar o mouse */
}

.audio-progress {
  margin-top: 10px;
}

.progress-bar {
  width: 100%;
  border-radius: 10px;
}

.time-display {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #ffffff;
}

.download-section {
  padding: 15px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
}

.download-btn {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 10%) 0%,
    rgb(255 255 255 / 5%) 70.31%
  );
  -webkit-clip-path: polygon(85% 0, 100% 20%, 100% 100%, 15% 100%, 0 80%, 0 0);
  clip-path: polygon(85% 0, 100% 20%, 100% 100%, 15% 100%, 0 80%, 0 0);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
  text-align: center;
  border: 2px solid #ffffff1a;
  transition: transform 0.5s ease, background-color 0.3s ease; /* Suave transição */
}

/* Efeito de hover */
.download-btn:hover {
  transform: scale(1.05); /* Aumenta a escala em 5% no hover */
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 20%) 0%,
    rgb(255 255 255 / 10%) 70.31%
  ); /* Fundo ligeiramente mais claro */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #00000099;
  padding: 20px;
  border-radius: 5px;
  backdrop-filter: blur(20px);
}

.collection-title {
  margin-bottom: 25px;
  color: white;
  text-align: center;
}
.versiculos-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 768px) {
  .versiculo-card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .versiculo-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


/* Estilos para o botão de compartilhamento */
.share-button {
    background-color: #0063d7;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 26px;
    height: 50px;
    justify-content: flex-end;
    line-height: 60px;
    margin: 0 20px;
    max-width: 50px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    transition: background-color .3s;
    width: 100%;
    padding: 14px;
    align-items: center;
}


.share-button:hover {
  background-color: #003879;
}

/* Estilos para a modal de compartilhamento */
.share-modal .modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 90%);
}

.share-modal .modal-header {
  background-color: transparent;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.share-modal .modal-title {
  color: #333;
  font-weight: bold;
}

.share-modal .modal-body {
  padding: 20px;
}

/* Estilo para o campo de link */
.share-link-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

/* Estilos para os botões de compartilhamento */
.share-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.share-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s;
}

.share-buttons button:hover {
  opacity: 0.8;
}

.whatsapp-button {
  background-color: #25D366;
}

.facebook-button {
  background-color: #3b5998;
}

.twitter-button {
  background-color: #1DA1F2;
}

/* Estilo para o botão de copiar link */
.copy-link-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-link-button:hover {
  background-color: #0056b3;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .share-buttons {
    flex-direction: column;
  }

  .share-buttons button {
    margin-bottom: 10px;
  }
}


