body {
    background: linear-gradient(
        to right,
        #020307,
        #000b2c,
        #002746,
        #000f35f3,
        #050c25,
        #000a27,
        #001b31,
        #010308
    );
    background-size: 400% 100%;
    font-family: 'Montserrat', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    position: relative;
    z-index: 1;
}



.adminPanelWrapper {
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background: linear-gradient(249deg, #ffffff00 0%, rgb(0 0 0) 70.31%);
    clip-path: polygon(85% 0, 100% 9%, 100% 100%, 0% 100%, 0 80%, 0 0);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid #ffffff73;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
}

.adminPanelWrapper::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 80%);
    transform: rotate(30deg);
    pointer-events: none;
}

.adminPanelWrapper h2 {
    color: #ffffff;
    text-align: left;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
    max-width: 500px;
}
.toneButton {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    background-color: #f0f0f000;
    cursor: pointer;
    transition: background-color 0.5s;
    border-radius: 10px;
}
  
  .toneButton:hover {
    background-color: #ffffff54;
  }
  
  .selectedTone {
    background: linear-gradient(to right, #020307, #000b2c, #002746, #01030813), linear-gradient(to right, #020307, #000a27, #002746, #010308);
    color: white;
    background-size: 300% 100%;
}
  
  .coverPreview {
    margin-top: 10px;
    max-width: 800px;
    margin-bottom: 10px;
    
  }
  
  .coverPreview img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 8px 9px 14px 0px rgb(0 0 0), 0 6px 20px rgb(0 0 0 / 0%);
    border: 1px solid #ffffff54;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formGroup label {
    font-weight: 500;
    color: #ffffff;
    font-size: 1.1em;
    letter-spacing: 2px;
    margin-bottom: 17px;
}

.formGroup input[type="text"],
.formGroup input[type="file"] {
    width: 100%;
    padding: 12px 15px;
    border: 2px solid #ffffff63;
    border-radius: 8px;
    font-size: 1em;
    transition: border-color 0.3s ease;
    background: #00000070;
    margin-bottom: 10px;
}

.formGroup input[type="text"]:focus,
.formGroup input[type="file"]:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.25);
}

.tonesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 12px;
    background-color: transparent;
    padding: 15px;
    border-radius: 8px;
    border: none;
}

.toneCheckbox {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: #34495e;
}

.toneCheckbox input[type="checkbox"] {
    margin-right: 8px;
    width: 18px;
    height: 18px;
}

button[type="submit"] {
    background-color: #ffffff08;
    color: white;
    border: 1px solid;
    padding: 15px 25px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 600;
    transition: background-color 0.5s ease, transform 0.9s ease;
    margin-top: 20px;
    width: 100%;
}

button[type="submit"]:hover {
    background: linear-gradient(to right, #020307, #000b2c, #002746, #01030813), linear-gradient(to right, #020307, #000a27, #002746, #010308);
    background-size: 300% 100%;
    transform: scale(1.05); /* Aplica o efeito de zoom suave */
}



.progressContainer {
    margin-top: 30px;
}

progress {
    width: 100%;
    height: 25px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 10px;
    overflow: hidden;
}

progress::-webkit-progress-bar {
    background-color: #ecf0f1;
}

progress::-webkit-progress-value {
    background-color: #3498db;
    transition: width 0.3s ease;
}

.progressText {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    color: #34495e;
}

@media (max-width: 600px) {
    .adminPanelWrapper {
        padding: 20px;
        width: 95%;
        margin: 20px auto;
    }

    .adminPanelWrapper h2 {
        font-size: 2em;
    }

    .tonesContainer {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    button[type="submit"] {
        width: 100%;
    }
}



  /* Estilo para o preenchimento da barra de progresso */
  .progressContainer progress::-webkit-progress-value {
    background-color: #00155b; /* Cor padrão, pode ser alterada conforme necessário */
    border-radius: 10px;
  }
  
  /* Estilo para navegadores Firefox */
  .progressContainer progress::-moz-progress-bar {
    background-color: #00155b; /* Cor padrão, pode ser alterada conforme necessário */
    
  }
  
  .progressText {
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
  }